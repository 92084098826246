import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {AdminLoginService} from './admin.login.service';
import {MyErrorStateMatcher} from '../../../MyErrorStateMatcher';
import {LoaderService} from '../../../loader.service';
import {Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-admin-login',
    templateUrl: './admin.login.html',
    styleUrls: ['./admin.login.scss'],
    providers: [AdminLoginService]
})
export class AdminLoginComponent implements OnInit, AfterViewInit {
    adminLoginForm: FormGroup;
    passwordinputtype = 'password';
    errorMessage;
    successMessage;
    errormatcher = new MyErrorStateMatcher();
    floatingLable = 'auto';
    winwidth;
    hideRequiredStar = false;

    constructor(private _formBuilder: FormBuilder,
                private router: Router,
                private loginService: AdminLoginService,
                private loaderService: LoaderService) {
        this.loaderService.loading(true);
    }

    changeInputType() {
        if (this.passwordinputtype === 'password') {
            this.passwordinputtype = 'text';
        } else {
            this.passwordinputtype = 'password';
        }
    }

    @HostListener('window:resize')
    onResize() {
        setTimeout(() => {
            this.winwidth = window.innerWidth;
        }, 500);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.winwidth = window.innerWidth;
            this.loaderService.loading(false);
        }, 500);
    }

    adminLogin() {
        this.nullifyMessages();
        if (this.adminLoginForm.invalid || this.adminLoginForm.status !== 'VALID') {
            return;
        }
        this.loaderService.loading(true);
        this.loginService.adminLogin(this.adminLoginForm.value).subscribe((response: any) => {
            this.loaderService.loading(false);
            if (response && response.data.token) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('admin_login_data', JSON.stringify(response.data));
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('admin_login_data', JSON.stringify(response.data));
                this.router.navigate(['/admin/dashboard']);
            }
        }, error => {
            this.loaderService.loading(false);
            this.errorMessage = error.error.message;
            this.adminLoginForm.get('password').setValue('');
        });
    }

    nullifyMessages() {
        this.successMessage = null;
        this.errorMessage = null;
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.winwidth = window.innerWidth;
        }, 1000);
        this.adminLoginForm = this._formBuilder.group({
            email: [null, Validators.compose([Validators.required, Validators.email])],
            password: [null, Validators.compose([Validators.required])]
        });
    }
}