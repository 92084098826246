import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {VerifyHouseOwnerNumberService} from './verify.house.owner.number.service';
import {Router} from '@angular/router';
import {MyErrorStateMatcher} from '../../MyErrorStateMatcher';
import {LoaderService} from '../../loader.service';

@Component({
    selector: 'app-verify-phone',
    templateUrl: './verify.house.owner.number.html',
    styleUrls: ['./verify.house.owner.number.scss'],
    providers: [VerifyHouseOwnerNumberService]
})
export class VerifyHouseOwnerNumberComponent implements OnInit, AfterViewInit {
    errorMessage;
    successMessage;
    verifyForm: FormGroup;
    floatingLable = 'always';
    errormatcher = new MyErrorStateMatcher();
    houseOwnerSignupFormData;
    isSubmited = false;
    constructor(private _formBuilder: FormBuilder,
                private router: Router,
                private loaderService: LoaderService,
                private verifyHouseOwnerNumberService: VerifyHouseOwnerNumberService) {
        this.loaderService.loading(true);
    }

    ngOnInit(): void {
        this.verifyForm = this._formBuilder.group({
            email: [null, Validators.compose([Validators.required])],
            phonenumber: [null, Validators.compose([Validators.required])],
            code: [null, Validators.compose([Validators.required])]
        });
        if (localStorage.getItem('houseOwner')) {
            this.houseOwnerSignupFormData = JSON.parse(localStorage.getItem('houseOwner'));
            this.verifyForm.get('phonenumber').setValue(this.houseOwnerSignupFormData.phonenumber);
            this.verifyForm.get('email').setValue(this.houseOwnerSignupFormData.email);
            if (this.houseOwnerSignupFormData.phonenumber) {
                this.onMobileEnter();
            }
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loaderService.loading(false);
        }, 1000);
    }

    onMobileEnter() {
        this.nullifyMessages();
        if (this.verifyForm.value.phonenumber && this.verifyForm.value.phonenumber.length === 10) {
            this.verifyHouseOwnerNumberService.sendOTP(this.verifyForm.value).subscribe((response: any) => {
                this.successMessage = response.message;
            }, error => {
                this.errorMessage = error.error.message;
            });
        } else {
            this.errorMessage = 'Please enter proper mobile number.';
        }
    }

    nullifyMessages() {
        this.successMessage = null;
        this.errorMessage = null;
    }

    verifyIt() {
        this.nullifyMessages();
        this.isSubmited = true;
        if (this.verifyForm.invalid || this.verifyForm.status !== 'VALID') {
            return;
        }
        debugger
        this.isSubmited = false;
        this.verifyHouseOwnerNumberService.verifyAccount(this.verifyForm.value).subscribe((response: any) => {
            this.successMessage = response.message;
            this.router.navigate(['completion-profile']);
        }, error => {
            this.errorMessage = error.error.message;
        });
    }

    ResetForm() {
        this.errorMessage = null;
        this.verifyForm.patchValue({code: ''});
    }
}
