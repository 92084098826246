import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../Constants';

@Injectable()
export class ResendVerificationEmailService {

    constructor(private http: HttpClient) {}

    resendEmail(data) {
        return this.http.post(Constants.BASE_API_URL + '/resend-email/house-owner', data);
    }

}