import {AfterViewInit, Component, HostListener, OnInit, ViewChild} from '@angular/core';
import {LoginService} from '../login/login.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MyErrorStateMatcher} from '../../MyErrorStateMatcher';
import {Router} from '@angular/router';
import {LoaderService} from '../../loader.service';
import {Observable} from 'rxjs/index';
import Swal from 'sweetalert2';

declare var gapi: any;
declare var $: any;

@Component({
    selector: 'app-signup',
    templateUrl: './signup.html',


    providers: [LoginService],
    styleUrls: ['./signup.scss']
})
export class SignupComponent implements OnInit, AfterViewInit {
    signup: FormGroup;
    errorMessage;
    successMessage;
    errormatcher = new MyErrorStateMatcher();
    floatingLable = 'auto';
    winwidth;
    isSubmitted = false;
    @ViewChild('google') google: any;
    hideRequiredStar = false;

    constructor(private _formBuilder: FormBuilder, private loginService: LoginService, private router: Router, private loaderService: LoaderService) {
        this.loaderService.loading(true);
    }

    @HostListener('window:resize')
    onResize() {
        setTimeout(() => {
            this.winwidth = window.innerWidth;
        }, 1000);
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.winwidth = window.innerWidth;
        }, 500);
        this.signup = this._formBuilder.group({
            firstName: [null, Validators.compose([Validators.required])],
            middleName: [null],
            lastName: [null, Validators.compose([Validators.required])],
            email: [null, Validators.compose([Validators.required, Validators.email])],
            password: [null, Validators.compose([Validators.required])],
            agreement: [null, Validators.compose([Validators.required])],
            phonenumber: [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(10)])]
        });
    }

    /**
     *
     * allow only a-z A-Z and <space> and <.(dot)>
     *
     * */
    OnlyAlphaNumericDataAllow(event: KeyboardEvent) {
        if (!((event.keyCode >= 65 && event.keyCode <= 90) || (event.keyCode >= 97 && event.keyCode <= 122))) {
            if (event.keyCode === 32 || event.keyCode === 46) {
                return true;
            } else {
                return false;
            }
        }
    }


    onKeyDown(event) {
        const e = <KeyboardEvent> event;
        if (event.key) {
            if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
                // Allow: Ctrl+A
                (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
                // Allow: Ctrl+C
                (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
                // Allow: Ctrl+V
                (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
                // Allow: Ctrl+X
                (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)) {
                // let it happen, don't do anything
                return;
            }
            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
                e.preventDefault();
            }
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.winwidth = window.innerWidth;
            this.loaderService.loading(false);
            this.loadGoogleAPI().subscribe((res) => {
            }, error => {
            });
            $('.mat-form-field-prefix').css('top', '-1.0px');
            $('.mat-form-field-prefix').css('padding-right', '5px');
        }, 500);
    }

    loadGoogleAPI(): Observable<any> {
        return new Observable((observer) => {
            gapi.load('client:auth2', {
                callback: function () {
                    // Initialize client & auth libraries
                    gapi.client.init({
                        clientId: '1012751695473-i0nfhj1vk0r6prv0vg3bv05l7t99m3u9.apps.googleusercontent.com',
                        scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/plus.me'
                    }).then(
                        function (success) {
                            observer.next(success);
                            observer.complete();
                        },
                        function (error) {
                            observer.error(error);
                            observer.complete();
                        }
                    );
                },
                onerror: function () {
                    observer.error('Some error occured.');
                    observer.complete();
                }
            });
        });
    }

    nullifyMessages() {
        this.successMessage = null;
        this.errorMessage = null;
    }

    houseOwnerSingup() {
        this.nullifyMessages();
        if (this.signup.invalid || this.signup.status !== 'VALID') {
            this.isSubmitted = true;
            return;
        }
        if (!this.signup.get('agreement').value) {
            Swal('Error!', 'Please accept terms and condition', 'error');
            this.isSubmitted = true;
            return;
        }
        this.loaderService.loading(true);
        this.isSubmitted = false;
        this.loginService.houseOwnerSignup(this.signup.value).subscribe((response: any) => {
            this.successMessage = response.message;
            this.loaderService.loading(false);
            this.signup.get('password').setValue(btoa(this.signup.value.password));
            localStorage.setItem('houseOwner', JSON.stringify(this.signup.value));
            this.router.navigate(['/verify-phone']);
            Swal('Success!', response.message, 'success');
        }, error => {
            Swal('Error!', error.error.message, 'error');
            this.loaderService.loading(false);
            this.errorMessage = error.error.message;
        });
    }

    googleApiLogin(): Observable<any> {
        return new Observable((observer) => {
            gapi.auth2.getAuthInstance().signIn().then(
                function (googleUser) {
                    observer.next(googleUser);
                    observer.complete();
                },
                function (error) {
                    observer.error(error);
                    observer.complete();
                }
            );
        });
    }

    loginWithGoogle() {
        this.googleApiLogin().subscribe((response: any) => {
            const profile = response.getBasicProfile();
            const body = {
                firstName: profile.getName(),
                middleName: profile.getGivenName(),
                lastName: profile.getFamilyName(),
                googleId: profile.getId(),
                email: profile.getEmail(),
                imageUrl: profile.getImageUrl()
            };
            sessionStorage.setItem('google-profile', JSON.stringify(body));
            this.router.navigate(['/google/password']);
            /*this.loginService.houseOwnerGoogleSignup(body).subscribe((res: any) => {
               this.successMessage = res.message;
            });*/
        }, error => {
            Swal('Error!', error.error.message, 'error');
        });
    }
}