import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {LoginService} from './login.service';
import {MyErrorStateMatcher} from '../../MyErrorStateMatcher';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoaderService} from '../../loader.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.html',
    providers: [LoginService],
    styleUrls: ['./login.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {
    houseOwnerLoginForm: FormGroup;
    tenantLoginForm: FormGroup;
    passwordinputtype = 'password';
    errorMessage;
    successMessage;
    errorMessage1;
    successMessage1;
    errormatcher = new MyErrorStateMatcher();
    floatingLable = 'auto';
    winwidth;
    hideRequiredMarker = false;

    constructor(private _formBuilder: FormBuilder,
                private router: Router,
                private loginService: LoginService,
                private loaderService: LoaderService) {
        this.loaderService.loading(true);
    }

    changeInputType() {
        if (this.passwordinputtype === 'password') {
            this.passwordinputtype = 'text';
        } else {
            this.passwordinputtype = 'password';
        }
    }

    @HostListener('window:resize')
    onResize() {
        setTimeout(() => {
            this.winwidth = window.innerWidth;
        }, 500);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.winwidth = window.innerWidth;
            this.loaderService.loading(false);
        }, 500);
    }

    onMobileEnter() {
        this.nullifyMessages();
        if (this.tenantLoginForm.value.phonenumber && this.tenantLoginForm.value.phonenumber.length === 10) {
            this.loginService.tenantLogin(this.tenantLoginForm.value).subscribe((response: any) => {
                this.successMessage1 = response.message;
            }, error => {
                this.errorMessage1 = error.error.message;
            });
        } else {
            this.errorMessage1 = 'Please enter proper mobile number.';
        }
    }

    houseOwnerLogin() {
        this.nullifyMessages();
        if (this.houseOwnerLoginForm.invalid || this.houseOwnerLoginForm.status !== 'VALID') {
            return;
        }
        this.loaderService.loading(true);
        this.loginService.houseOwnerLogin(this.houseOwnerLoginForm.value).subscribe((response: any) => {
            this.loaderService.loading(false);
            if (response && response.data.token) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('ho_login_data', JSON.stringify(response.data));
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('ho_login_data', JSON.stringify(response.data));
                this.router.navigate(['houseowner/dashboard']);
            }
        }, error => {
            this.loaderService.loading(false);
            this.errorMessage = error.error.message;
            this.houseOwnerLoginForm.get('password').setValue('');
        });
    }

    nullifyMessages() {
        this.successMessage = null;
        this.errorMessage = null;
        this.successMessage1 = null;
        this.errorMessage1 = null;
    }

    tenantLogin() {
        this.nullifyMessages();
        if (this.tenantLoginForm.invalid || this.tenantLoginForm.status !== 'VALID') {
            return;
        }
        this.loaderService.loading(true);
        this.loginService.tenantLogin(this.tenantLoginForm.value).subscribe((response: any) => {
            this.loaderService.loading(false);
            if (response && response.data.token) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('tenant_login_data', JSON.stringify(response.data));
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('tenant_login_data', JSON.stringify(response.data));
                this.router.navigate(['tenant/dashboard']);
            }
        }, error => {
            this.loaderService.loading(false);
            this.errorMessage1 = error.error.message;
        });
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.winwidth = window.innerWidth;
        }, 1000);
        this.houseOwnerLoginForm = this._formBuilder.group({
            email: [null, Validators.compose([Validators.required])],
            password: [null, Validators.compose([Validators.required])]
        });
        this.tenantLoginForm = this._formBuilder.group({
            phonenumber: [null, Validators.compose([Validators.required])],
            code: [null, Validators.compose([Validators.required])]
        });
    }
}