import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../../Constants';

@Injectable()
export class AdminLoginService {

    constructor(private http: HttpClient) {}

    adminLogin(data: any) {
        return this.http.post(Constants.BASE_API_URL + '/admin/auth', data);
    }
}