import {Component, OnInit} from '@angular/core';
import {LoaderService} from './loader.service';
import {NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Constant} from './Constants';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [LoaderService]
})
export class AppComponent implements OnInit {
    loading = false;

    constructor(public loaderService: LoaderService, private router: Router, private titleService: Title) {
    }

    ngOnInit(): void {
        if (localStorage.getItem('my_div_flag')) {
            const urlSplitter = document.location.href.split('/');
            if (urlSplitter[3] === 'admin') {
                Constant.MY_DIV_FLAG = (localStorage.getItem('my_div_flag') === 'true' ? true : false);
            }
        }
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const title = this.getTitle(this.router.routerState, this.router.routerState.root).join('-');
                /*console.log('title', title);*/
                this.titleService.setTitle(title);
            }
        });
    }

    // collect that title data properties from all child routes
    // there might be a better way but this worked for me
    getTitle(state, parent) {
        const data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push(parent.snapshot.data.title);
        }

        if (state && parent) {
            data.push(... this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }
}
