import {AfterViewInit, Component, OnInit} from '@angular/core';
import {LoaderService} from '../../loader.service';

@Component({
    selector: 'app-terms-and-condition',
    templateUrl: './terms.and.condition.html',
    styleUrls: ['./terms.and.condition.scss']
})
export class TermsAndConditionComponent implements OnInit, AfterViewInit {

    constructor(private loaderService: LoaderService) {
        this.loaderService.loading(true);
    }

    ngOnInit(): void {

    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loaderService.loading(false);
        }, 500);
    }
}