import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {MyErrorStateMatcher} from '../../MyErrorStateMatcher';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {PoliceLoginService} from './police.login.service';
import {LoaderService} from '../../loader.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-police-login',
    templateUrl: './police.login.html',
    providers: [PoliceLoginService],
    styleUrls: ['./police.login.scss']
})
export class PoliceLoginComponent implements OnInit, AfterViewInit {
    policeLoginForm: FormGroup;
    passwordinputtype = 'password';
    errorMessage;
    successMessage;
    errormatcher = new MyErrorStateMatcher();
    floatingLable = 'auto';
    winwidth;
    hideRequiredStar = false;

    constructor(private _formBuilder: FormBuilder,
                private router: Router,
                private loginService: PoliceLoginService,
                private loaderService: LoaderService) {
        this.loaderService.loading(true);
    }

    changeInputType() {
        if (this.passwordinputtype === 'password') {
            this.passwordinputtype = 'text';
        } else {
            this.passwordinputtype = 'password';
        }
    }

    @HostListener('window:resize')
    onResize() {
        setTimeout(() => {
            this.winwidth = window.innerWidth;
        }, 1000);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.winwidth = window.innerWidth;
            this.loaderService.loading(false);
        }, 500);
    }

    policeLogin() {
        this.nullifyMessages();
        if (this.policeLoginForm.invalid || this.policeLoginForm.status !== 'VALID') {
            return;
        }
        this.loaderService.loading(true);
        this.loginService.policeLogin(this.policeLoginForm.value).subscribe((response: any) => {
            this.loaderService.loading(false);
            if (response && response.data.token) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('police_login_data', JSON.stringify(response.data));
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('police_login_data', JSON.stringify(response.data));
                this.router.navigate(['/police/dashboard']);
            }
        }, error => {
            this.loaderService.loading(false);
            this.policeLoginForm.get('password').setValue('');
            this.errorMessage = error.error.message;
        });
    }

    nullifyMessages() {
        this.successMessage = null;
        this.errorMessage = null;
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.winwidth = window.innerWidth;
        }, 1000);
        this.policeLoginForm = this._formBuilder.group({
            email: [null, Validators.compose([Validators.required, Validators.email])],
            password: [null, Validators.compose([Validators.required])]
        });
    }
}
