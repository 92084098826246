import {FormControl, FormGroupDirective, NgForm} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {Constant} from './Constants';

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        let isSubmitted = form && form.submitted;
        const urlSplitter = document.location.href.split('/');
        if (urlSplitter[3] === 'admin' && Constant.IS_SUBMIT_CLICKED) {
            isSubmitted = true;
        }
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}
