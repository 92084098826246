import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../Constants';

@Injectable()
export class PoliceLoginService {
    constructor(private http: HttpClient) {}

    policeLogin(data) {
        return this.http.post(Constants.BASE_API_URL + '/auth/police', data);
    }
}