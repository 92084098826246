import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {fadeAnimation} from '../animations';

declare var $: any;

@Component({
    selector: 'app-noauth',
    templateUrl: './noauth.html',
    animations: [fadeAnimation]
})
export class NoauthComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        if (localStorage.getItem('ho_login_data') && localStorage.getItem('token')) {
            this.router.navigate(['/houseowner/dashboard']);
        } else if (localStorage.getItem('admin_login_data') && localStorage.getItem('token')) {
            this.router.navigate(['/admin/dashboard']);
        } else if (localStorage.getItem('police_login_data') && localStorage.getItem('token')) {
            this.router.navigate(['/police/dashboard']);
        }  else if (localStorage.getItem('tenant_login_data') && localStorage.getItem('token')) {
            this.router.navigate(['/tenant/dashboard']);
        }
    }

    ngAfterViewInit(): void {
        $('#sidebtn').click(function (event) {
            $('body').toggleClass('pushbody');
            $('#bs-example-navbar-collapse-1').toggleClass('in');
        });
    }

    ngOnDestroy(): void {
        /*localStorage.removeItem('houseOwner');*/
    }

    clicked() {
        if (window.innerWidth < 765) {
            $('body').toggleClass('pushbody');
            $('#bs-example-navbar-collapse-1').toggleClass('in');
        }
    }

}
