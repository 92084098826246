import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../Constants';

@Injectable()
export class ForgotPasswordService {

    constructor(private http: HttpClient) {}

    forgotPasswordByEmail(data) {
        return this.http.post(Constants.BASE_API_URL + '/reset-password-email/house-owner', data);
    }

    forgotPasswordByPhone(data) {
        return this.http.post(Constants.BASE_API_URL + '/reset-password-phone/house-owner', data);
    }

    forgotPasswordByPoliceEmail(data) {
        return this.http.post(Constants.BASE_API_URL + '/reset-password-email/police', data);
    }

    forgotPasswordByPolicePhone(data) {
        return this.http.post(Constants.BASE_API_URL + '/reset-password-phone/police', data);
    }
}