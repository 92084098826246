import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ResendVerificationEmailService} from './resend.verification.email.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MyErrorStateMatcher} from '../../MyErrorStateMatcher';
import {LoaderService} from '../../loader.service';

@Component({
    selector: 'app-resend-verification-email',
    templateUrl: './resend.verification.email.html',
    styleUrls: ['./resend.verification.email.scss'],
    providers: [ResendVerificationEmailService]
})
export class ResendVerificationEmailComponent implements OnInit, AfterViewInit {
    errormatcher = new MyErrorStateMatcher();
    errorMessage;
    successMessage;
    resendEmailForm: FormGroup;
    floatingLable = 'always';
    isPolice = false;

    constructor(private _formBuilder: FormBuilder,
                private router: Router,
                private loaderService: LoaderService,
                private activatedRoutes: ActivatedRoute,
                private resendVerificationEmailService: ResendVerificationEmailService) {
        this.loaderService.loading(true);
    }

    ngOnInit(): void {
        this.resendEmailForm = this._formBuilder.group({
            email: [null, Validators.compose([Validators.required, Validators.email])]
        });
        if (this.activatedRoutes.snapshot.params.type === 'police') {
            this.isPolice = true;
        } else {
            this.isPolice = false;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loaderService.loading(false);
        }, 1000);
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    nullifyMessages() {
        this.successMessage = null;
        this.errorMessage = null;
    }

    resendEmailVerification() {
        debugger
        this.nullifyMessages();
        if (this.resendEmailForm.invalid || this.resendEmailForm.status !== 'VALID') {
            return;
        }
        this.loaderService.loading(true);
        this.resendVerificationEmailService.resendEmail(this.resendEmailForm.value).subscribe((response: any) => {
            this.loaderService.loading(false);
            this.successMessage = response.message;
            this.resendEmailForm.reset();
        }, error => {
            this.loaderService.loading(false);
            this.errorMessage = error.error.message;
        });
    }
}
