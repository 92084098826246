import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ForgotPasswordResetService} from './forgot.password.reset.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MyErrorStateMatcher} from '../../MyErrorStateMatcher';
import {LoaderService} from '../../loader.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-forgot-password-reset',
    templateUrl: './forgot.password.reset.html',
    providers: [ForgotPasswordResetService]
})
export class ForgotPasswordResetComponent implements OnInit, AfterViewInit {
    errorMessage;
    successMessage;
    forgotPasswordResetForm: FormGroup;
    floatingLable = 'always';
    errormatcher = new MyErrorStateMatcher();
    id;
    code;
    isEmail;
    isPhone;
    isPolice;
    isSubmited = false;

    constructor(private _formBuilder: FormBuilder,
                private activatedRoutes: ActivatedRoute,
                private router: Router,
                private loaderService: LoaderService,
                private forgotPasswordService: ForgotPasswordResetService) {
        this.loaderService.loading(true);
    }

    ngOnInit(): void {
        this.id = this.activatedRoutes.snapshot.params.id;
        this.code = this.activatedRoutes.snapshot.params.code;
        this.isPhone = this.validatePhone(this.id);
        if (this.activatedRoutes.snapshot.queryParams.type && this.activatedRoutes.snapshot.queryParams.type === 'email') {
            this.isPhone = false;
        }
        this.isEmail = !this.isPhone;
        this.forgotPasswordResetForm = this._formBuilder.group({
            password: [null, Validators.compose([Validators.required])],
            confirm_password: [null, Validators.compose([Validators.required])]
        });
        if (this.activatedRoutes.snapshot.params.type === 'police') {
            this.isPolice = true;
        } else {
            this.isPolice = false;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loaderService.loading(false);
        }, 1000);
    }

    nullifyMessages() {
        this.successMessage = null;
        this.errorMessage = null;
    }

    forgotResetPassword() {
        this.nullifyMessages();
        this.isSubmited = true;
        if (this.forgotPasswordResetForm.invalid || this.forgotPasswordResetForm.status !== 'VALID') {
            return;
        }
        this.isSubmited = false;
        if (this.forgotPasswordResetForm.value.password !== this.forgotPasswordResetForm.value.confirm_password) {
            this.errorMessage = 'Password and confirm password must match.';
            return;
        }
        const body = this.forgotPasswordResetForm.value;
        body['code'] = this.code;
        if (this.isPhone) {
            body['phonenumber'] = this.id;
            if (this.isPolice) {
                this.forgotPasswordService.resetPasswordByPolicePhone(body).subscribe((response: any) => {
                    this.successMessage = response.message;
                    this.forgotPasswordResetForm.reset();
                    Swal('Success!', response.message, 'success');
                    setTimeout(() => {
                        this.router.navigate(['police/login']);
                    }, 3000);
                }, error => {
                    this.errorMessage = error.error.message;
                    Swal('Error!', error.error.message, 'error');
                });
            } else {
                this.forgotPasswordService.resetPasswordByPhone(body).subscribe((response: any) => {
                    this.successMessage = response.message;
                    this.forgotPasswordResetForm.reset();
                    Swal('Success!', response.message, 'success');
                    setTimeout(() => {
                        this.router.navigate(['']);
                    }, 3000);
                }, error => {
                    Swal('Error!', error.error.message, 'error');
                    this.errorMessage = error.error.message;
                });
            }
        } else {
            body['id'] = this.id;
            if (this.isPolice) {
                this.forgotPasswordService.resetPasswordByPoliceEmail(body).subscribe((response: any) => {
                    this.successMessage = response.message;
                    this.forgotPasswordResetForm.reset();
                    Swal('Success!', response.message, 'success');
                    setTimeout(() => {
                        this.router.navigate(['police/login']);
                    }, 3000);
                }, error => {
                    Swal('Error!', error.error.message, 'error');
                    this.errorMessage = error.error.message;
                });
            } else {
                this.forgotPasswordService.resetPasswordByEmail(body).subscribe((response: any) => {
                    this.successMessage = response.message;
                    this.forgotPasswordResetForm.reset();
                    Swal('Success!', response.message, 'success');
                    setTimeout(() => {
                        this.router.navigate(['']);
                    }, 3000);
                }, error => {
                    Swal('Error!', error.error.message, 'error');
                    this.errorMessage = error.error.message;
                });
            }
        }
    }

    validatePhone(phone) {
        let re = /^(\+\d{3})?\d{9}$/;
        let rt = re.test(phone.replace(/\s+/g, ''));
        if (!rt) {
            re = /^[0-9]+$/;
            rt = re.test(phone.replace(/\s+/g, ''));
        }
        return rt;
    }

}
