import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(public router: Router) { }

    canActivate() {
        if (localStorage.getItem('token') != null) {
            return true;
        } else {
            const data = JSON.parse(localStorage.getItem('login_data'));
            if (this.router.url === '/') {
                this.router.navigate(['/dashboard']);
            /*} else {
                this.router.navigate(['/']);*/
            }
            return false;
        }
    }
}
