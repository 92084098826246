import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HouseownerVerifyEmailService} from './houseowner.verify.email.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MyErrorStateMatcher} from '../../MyErrorStateMatcher';
import {LoaderService} from '../../loader.service';

@Component({
    selector: 'app-verify-email',
    templateUrl: './houseowner.verify.email.html',
    styleUrls: ['./houseowner.verify.email.scss'],
    providers: [HouseownerVerifyEmailService]
})
export class HouseownerVerifyEmailComponent implements OnInit, AfterViewInit {
    errormatcher = new MyErrorStateMatcher();
    errorMessage;
    successMessage;
    floatingLable = 'always';
    isPolice = false;

    constructor(private _formBuilder: FormBuilder,
                private router: Router,
                private loaderService: LoaderService,
                private activatedRoutes: ActivatedRoute,
                private houseownerVerifyEmailService: HouseownerVerifyEmailService) {
        this.loaderService.loading(true);
    }

    ngOnInit(): void {
        if (this.activatedRoutes.snapshot.params.type === 'police') {
            this.isPolice = true;
        } else {
            this.isPolice = false;
        }
        this.verifyMyEmail();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loaderService.loading(false);
        }, 1000);
    }

    nullifyMessages() {
        this.successMessage = null;
        this.errorMessage = null;
    }

    verifyMyEmail() {
        this.nullifyMessages();
        const body = {
            id: this.activatedRoutes.snapshot.params.id,
            code: this.activatedRoutes.snapshot.params.code
        }
        this.houseownerVerifyEmailService.verifyMyEmail(body).subscribe((response: any) => {
            this.successMessage = response.message;
        }, error => {
            this.errorMessage = error.error.message;
        });
    }
}
