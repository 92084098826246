import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../Constants';

@Injectable()
export class RandomAdvertisementService {
    constructor(private http: HttpClient) {
    }

    RandomAdvertisement(data) {
        return this.http.get(Constants.BASE_API_URL + '/advertisement/' + data);
    }
}