import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MyErrorStateMatcher} from '../../MyErrorStateMatcher';
import {LoaderService} from '../../loader.service';
import {ForgotPasswordResetService} from '../forgot.password.reset/forgot.password.reset.service';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-forgot-password-reset-otp',
    templateUrl: './forgot.password.reset.otp.html',
    providers: [ForgotPasswordResetService]
})
export class ForgotPasswordResetOtpComponent implements OnInit, AfterViewInit {
    errorMessage;
    successMessage;
    forgotPasswordResetForm: FormGroup;
    floatingLable = 'always';
    errormatcher = new MyErrorStateMatcher();
    phonenumber;
    isPhone;
    isPolice;
    isSubmited = false;

    constructor(private _formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private forgotPasswordResetService: ForgotPasswordResetService,
                private router: Router,
                private activatedRoutes: ActivatedRoute) {
        this.loaderService.loading(true);
    }

    ngOnInit(): void {
        this.phonenumber = this.activatedRoutes.snapshot.params.phonenumber;
        this.isPhone = this.validatePhone(this.phonenumber);
        if (!this.isPhone) {
            this.errorMessage = 'Phone number is incorrect.';
        }
        this.forgotPasswordResetForm = this._formBuilder.group({
            otp: [null, Validators.compose([Validators.required])]
        });
        if (this.activatedRoutes.snapshot.params.type === 'police') {
            this.isPolice = true;
        } else {
            this.isPolice = false;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loaderService.loading(false);
        }, 1000);
    }

    nullifyMessages() {
        this.successMessage = null;
        this.errorMessage = null;
    }

    forgotResetPassword() {
        this.nullifyMessages();
        if (this.forgotPasswordResetForm.invalid || this.forgotPasswordResetForm.status !== 'VALID') {
            this.isSubmited = true;
            return;
        }
        this.isSubmited = false;
        const body = {
            code: this.forgotPasswordResetForm.value.otp,
            phonenumber: this.phonenumber
        }
        if (this.isPhone && this.isPolice === false) {
            this.loaderService.loading(true);
            this.forgotPasswordResetService.resetPasswordByPhoneCheck(body).subscribe((response: any) => {
                this.loaderService.loading(false);
                this.router.navigate(['/reset-password/' + this.forgotPasswordResetForm.value.otp + '/' + this.phonenumber]);
            }, error => {
                Swal(
                    'Error!',
                    error.error.message,
                    'error'
                );
                this.loaderService.loading(false);
            });
        } else if (this.isPhone && this.isPolice) {
            this.loaderService.loading(true);
            this.forgotPasswordResetService.resetPasswordByPolicePhoneCheck(body).subscribe((response: any) => {
                this.loaderService.loading(false);
                this.router.navigate(['/police/reset-password/' + this.forgotPasswordResetForm.value.otp + '/' + this.phonenumber]);
            }, error => {
                Swal(
                    'Error!',
                    error.error.message,
                    'error'
                );
                this.loaderService.loading(false);
            });
        }
    }

    validatePhone(phone) {
        let re = /^(\+\d{3})?\d{9}$/;
        let rt = re.test(phone.replace(/\s+/g, ''));
        if (!rt) {
            re = /^[0-9]+$/;
            rt = re.test(phone.replace(/\s+/g, ''));
        }
        return rt;
    }

}
