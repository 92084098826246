import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './auth/AuthGuard';

const mainRoutes: Routes = [
    {path: '', loadChildren: './noauth/noauth.module#NoauthModule'},
    {path: '', loadChildren: './auth/auth.module#AuthModule', canActivate: [AuthGuard]}
]

@NgModule({
    imports: [RouterModule.forRoot(mainRoutes)],
    exports: [RouterModule]
})
export class AppRoutingModule {}