import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../Constants';

@Injectable()
export class ForgotPasswordResetService {

    constructor(private http: HttpClient) {}

    resetPasswordByPhone(data) {
        return this.http.post(Constants.BASE_API_URL + '/verify-reset-password-phone/house-owner', data);
    }

    resetPasswordByPhoneCheck(data) {
        return this.http.post(Constants.BASE_API_URL + '/verify-reset-password-phone-check/house-owner', data);
    }

    resetPasswordByEmail(data) {
        return this.http.post(Constants.BASE_API_URL + '/verify-reset-password/house-owner', data);
    }

    resetPasswordByPolicePhone(data) {
        return this.http.post(Constants.BASE_API_URL + '/verify-reset-password-phone/police', data);
    }

    resetPasswordByPolicePhoneCheck(data) {
        return this.http.post(Constants.BASE_API_URL + '/verify-reset-password-phone-check/police', data);
    }

    resetPasswordByPoliceEmail(data) {
        return this.http.post(Constants.BASE_API_URL + '/verify-reset-password/police', data);
    }
}