import {Injectable} from '@angular/core';
declare var $: any;

@Injectable()
export class LoaderService {

    public static isLoading = false;

    public loading(flag: boolean) {
        setTimeout(() => {
            if (flag) {
                $('body').addClass('no-scroll-css');
                $('html').addClass('no-scroll-css');
            } else {
                $('body').removeClass('no-scroll-css');
                $('html').removeClass('no-scroll-css');
            }
            LoaderService.isLoading = flag;
            return flag;
        }, 200);
    }

    public getIsLoading() {
        return LoaderService.isLoading;
    }
}