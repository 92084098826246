import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MyErrorStateMatcher} from '../../MyErrorStateMatcher';
import {HomeService} from '../home/home.service';
import {LoaderService} from '../../loader.service';
import {Constants} from '../../Constants';
import Swal from 'sweetalert2';
import {RandomAdvertisementService} from '../../auth/RandomAdvertisementService';
import {Observable} from 'rxjs/index';
import {RequestCallbackComponent} from './request.callback/request.callback.component';
import {MatDialog} from '@angular/material';

declare var google: any;
declare var $: any;

@Component({
    selector: 'app-place-description',
    templateUrl: './place.description.html',
    styleUrls: ['./place.description.scss'],
    providers: [HomeService, RandomAdvertisementService],
})
export class PlaceDescriptionComponent implements OnInit, AfterViewInit {
    homeForm: FormGroup;
    floatingLable = 'auto';
    errormatcher = new MyErrorStateMatcher();
    successMessage;
    map;
    /* property = [
         {
             name: 'Name of property',
             image: './assets/imgs/home-p-i.jpg',
             price: '$ 1000 / mo.'
         },
         {
             name: 'Name of property',
             image: './assets/imgs/home-p-i.jpg',
             price: '$ 1000 / mo.'
         },
         {
             name: 'Name of property',
             image: './assets/imgs/home-p-i.jpg',
             price: '$ 1000 / mo.'
         },
         {
             name: 'Name of property',
             image: './assets/imgs/home-p-i.jpg',
             price: '$ 1000 / mo.'
         }
     ]*/
    bunches = [];
    propertyDet: any = {};
    advt1;
    advt2;
    advt3;
    advt4;

    constructor(private router: Router, private _formBuilder: FormBuilder,
                private activatedRoutes: ActivatedRoute,
                private dialog: MatDialog,
                private randomAdvertisementService: RandomAdvertisementService,
                private homeService: HomeService,
                private loaderService: LoaderService) {
        this.loaderService.loading(true);
    }

    InquirySubmit() {
        if (this.homeForm.invalid || this.homeForm.status !== 'VALID') {
            return;
        }
        Swal('Success!', 'Thank you for get in touch. Our customer executive will shortly contact you!', 'success');
        this.homeService.getInTouch(this.homeForm.value).subscribe((response: any) => {
        });
        this.homeForm.reset();
    }

    ngOnInit(): void {
        this.homeForm = this._formBuilder.group({
            name: [null, Validators.compose([Validators.required])],
            email: [null, Validators.compose([Validators.required, Validators.email])],
            phone: [null, Validators.compose([Validators.required])],
            interestedProduct: [null, Validators.compose([Validators.required])],
            comments: [null, Validators.compose([Validators.required])],
        });
        setTimeout(() => {
            this.successMessage = null;
        }, 5000);
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.Perform();
            }
        });
        this.Perform();
        /*this.router.*/
        this.FetchAdvertisement('?top=true').subscribe((response: any) => {
            this.advt1 = 'url("' + response.data.image + '")';
        }, error => {
        });
        this.FetchAdvertisement('?header=true').subscribe((response: any) => {
            this.advt2 = 'url("' + response.data.image + '")';
        }, error => {
        });
        this.FetchAdvertisement('?footer=true').subscribe((response: any) => {
            this.advt3 = 'url("' + response.data.image + '")';
        }, error => {
        });
        this.FetchAdvertisement('?bottom=true').subscribe((response: any) => {
            this.advt4 = 'url("' + response.data.image + '")';
        }, error => {
        });
    }

    private Perform() {
        if (this.activatedRoutes.snapshot.queryParams.id) {
            this.homeService.fetchProperties(this.activatedRoutes.snapshot.queryParams.id).subscribe((response: any) => {
                this.propertyDet = response.data;
                if (google) {
                    const bounds = new google.maps.LatLngBounds();
                    const address = this.propertyDet.address1 + ', ' + this.propertyDet.city + ', ' + this.propertyDet.state + ', ' + this.propertyDet.pincode;
                    const geocoder = new google.maps.Geocoder();
                    this.fetchLatLong(address, geocoder, this.map).subscribe((resp: any) => {
                        const marker = new google.maps.Marker({
                            position: resp,
                            animation: google.maps.Animation.DROP,
                            map: this.map
                        });
                        window.setTimeout(function () {
                            let size = 2;
                            if (window.outerWidth < 450) {
                                size = 0;
                            }
                            this.map.setZoom(size);
                            this.map.setCenter(marker.getPosition());
                            console.log('done');
                        }, 3000);
                        bounds.extend(marker.getPosition());
                    });
                }
                $('html, body').animate({scrollTop: 0}, 'slow');
            });
            this.homeService.fetchSimilarProperties(this.activatedRoutes.snapshot.queryParams.id).subscribe((response: any) => {
                const allProperties = response.data;
                let bunchProperties = [];
                this.bunches = [];
                for (let i = 0; i < allProperties.length; i++) {
                    if (i % 4 === 0 && i !== 0) {
                        this.bunches.push(bunchProperties);
                        bunchProperties = [];
                    }
                    bunchProperties.push(allProperties[i]);
                }
                if (bunchProperties.length > 0) {
                    this.bunches.push(bunchProperties);
                }
            });
        } else {
            this.router.navigate(['']);
        }
    }

    FetchAdvertisement(data) {
        return this.randomAdvertisementService.RandomAdvertisement(data);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loaderService.loading(false);
            this.map = this.MapInitialize(this.map);
        }, Constants.LOADER_TIME);
    }


    MapInitialize(maps) {
        return initMap(maps);

        function initMap(map: any) {

            const mapOptions = {
                center: new google.maps.LatLng(23.019545, 72.588225),
                zoom: 4,
                minZoom: 0
            };

            map = new google.maps.Map(document.getElementById('map'), mapOptions);
            console.log(map);
            return map;
        }
    }

    fetchLatLong(address, geocoder, map) {
        return new Observable((observer) => {
            if (localStorage.getItem(address)) {
                observer.next(JSON.parse(localStorage.getItem(address)));
            } else {
                geocoder.geocode({'address': address}, function (results, status) {
                    if (status === 'OK') {
                        /*console.log(address + ' => ')
                        console.log('lat' + ' => ' + results[0].geometry.location.lat())
                        console.log('lat' + ' => ' + results[0].geometry.location.lng())*/
                        localStorage.setItem(address, JSON.stringify(results[0].geometry.location));
                        observer.next(results[0].geometry.location);
                    } else {
                        console.log('Geocode was not successful for the following reason: ' + status);
                    }
                });
            }
        });
    }

    RequestCallBack(id) {
        debugger
        const dialogRef = this.dialog.open(RequestCallbackComponent, {
            width: '800px',
            data: {id: id}
        });
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.successMessage = result.message;
                Swal('Success!', this.successMessage, 'success');
                setTimeout(() => {
                    this.successMessage = null;
                }, 5000);
            }
        });
    }
}