import {Component} from '@angular/core';
import {LoginService} from '../login/login.service';
import {LoaderService} from '../../loader.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-complete-signup',
    templateUrl: './completion.houseowner.signup.html',
    styleUrls: ['./completion.houseownersignup.scss'],
    providers: [LoginService, LoaderService]
})
export class CompletionHouseownerComponent {

    constructor(private loginService: LoginService, private loaderService: LoaderService, private router: Router) {
    }

    updateMyProfile() {
        this.CommonFunction('/houseowner/update-profile');
    }
    
    private CommonFunction(routePath: string) {
        if (localStorage.getItem('houseOwner')) {
            const houseOwner = JSON.parse(localStorage.getItem('houseOwner'));
            if (houseOwner) {
                console.log(atob(houseOwner.password));
                const body = {
                    email: houseOwner.email,
                    password: atob(houseOwner.password)
                }
                this.loginService.houseOwnerLogin(body).subscribe((response: any) => {
                    localStorage.clear();
                    if (response && response.data.token) {
                        localStorage.setItem('token', response.data.token);
                        localStorage.setItem('ho_login_data', JSON.stringify(response.data));
                        sessionStorage.setItem('token', response.data.token);
                        sessionStorage.setItem('ho_login_data', JSON.stringify(response.data));
                        this.router.navigate([routePath]);
                    } else {
                        this.router.navigate(['']);
                    }
                }, error => {
                    this.router.navigate(['']);
                });
            } else {
                this.router.navigate(['']);
            }
        } else {
            this.router.navigate(['']);
        }
    }

    skipFeature() {
        this.CommonFunction('/houseowner/dashboard');
    }
}