import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {MyErrorStateMatcher} from '../../../MyErrorStateMatcher';
import {HomeService} from '../../home/home.service';

@Component({
    selector: 'app-request-callback',
    templateUrl: './request.callback.html',
    styleUrls: ['./request.callback.scss'],
    providers: [HomeService]
})
export class RequestCallbackComponent implements OnInit {
    hideRequiredMarker = false;
    requestCallBackForm: FormGroup;
    floatingLable = 'auto';
    errorMessage;
    successMessage;
    errormatcher = new MyErrorStateMatcher();

    constructor(private _formBuilder: FormBuilder,
                private homeService: HomeService,
                public dialogRef: MatDialogRef<RequestCallbackComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.requestCallBackForm = this._formBuilder.group({
            propertyId: [null, Validators.compose([Validators.required])],
            email: [null, Validators.compose([Validators.required])],
            name: [null, Validators.compose([Validators.required])],
            phoneNumber: [null, Validators.compose([Validators.required])],
            subject: [null, Validators.compose([Validators.required])],
            message: [null],
        });
        this.requestCallBackForm.patchValue({propertyId: this.data.id});
    }

    OnlyNumeric(event) {
        const e = <KeyboardEvent> event;
        if (event.key) {
            if ([46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
                // Allow: Ctrl+A
                (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
                // Allow: Ctrl+C
                (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
                // Allow: Ctrl+V
                (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
                // Allow: Ctrl+X
                (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
                // Allow: home, end, left, right
                (e.keyCode >= 35 && e.keyCode <= 39)) {
                // let it happen, don't do anything
                return;
            }
            // Ensure that it is a number and stop the keypress
            if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode > 96 || e.keyCode < 105)) {
                e.preventDefault();
                return false;
            }
        }
    }

    SubmitForm() {
        debugger
        if (this.requestCallBackForm.invalid || this.requestCallBackForm.status !== 'VALID') {
            return;
        }
        this.homeService.requestCallBack(this.requestCallBackForm.value).subscribe((response: any) => {
            this.successMessage = response.message;
            this.dialogRef.close(response);
        }, error => {
            this.errorMessage = error.error.message;
        });
    }
}