import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../Constants';

@Injectable()
export class LoginService {
    constructor(private http: HttpClient) {}

    houseOwnerLogin(data) {
        return this.http.post(Constants.BASE_API_URL + '/auth/house-owner', data);
    }

    houseOwnerSignup(data) {
        return this.http.post(Constants.BASE_API_URL + '/signup/house-owner', data);
    }

    houseOwnerGoogleSignup(data) {
        return this.http.post(Constants.BASE_API_URL + '/signup/google/house-owner', data);
    }

    tenantLogin(data) {
        return this.http.post(Constants.BASE_API_URL + '/auth/tenant', data);
    }
}