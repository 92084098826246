import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ForgotPasswordService} from './forgot.password.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MyErrorStateMatcher} from '../../MyErrorStateMatcher';
import {LoaderService} from '../../loader.service';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot.password.html',
    styleUrls: ['./forgot.password.scss'],
    providers: [ForgotPasswordService]
})
export class ForgotPasswordComponent implements OnInit, AfterViewInit {
    errormatcher = new MyErrorStateMatcher();
    errorMessage;
    successMessage;
    forgotPasswordForm: FormGroup;
    floatingLable = 'always';
    isPolice = false;
    isSubmited = false;

    constructor(private _formBuilder: FormBuilder,
                private router: Router,
                private loaderService: LoaderService,
                private activatedRoutes: ActivatedRoute,
                private forgotPasswordService: ForgotPasswordService) {
        this.loaderService.loading(true);
    }

    ngOnInit(): void {
        this.forgotPasswordForm = this._formBuilder.group({
            email: [null, Validators.compose([Validators.required])]
        });
        if (this.activatedRoutes.snapshot.params.type === 'police') {
            this.isPolice = true;
        } else {
            this.isPolice = false;
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loaderService.loading(false);
        }, 1000);
    }

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    nullifyMessages() {
        this.successMessage = null;
        this.errorMessage = null;
    }

    validatePhone(phone) {
        let re = /^(\+\d{3})?\d{9}$/;
        let rt = re.test(phone.replace(/\s+/g, ''));
        if (!rt) {
            re = /^[0-9]+$/;
            rt = re.test(phone.replace(/\s+/g, ''));
        }
        return rt;
    }

    forgotPassword() {
        this.isSubmited = true;
        this.nullifyMessages();
        if (this.forgotPasswordForm.invalid || this.forgotPasswordForm.status !== 'VALID') {
            return;
        }
        const isEmail = this.validateEmail(this.forgotPasswordForm.value.email);
        const isPhone = this.validatePhone(this.forgotPasswordForm.value.email);
        this.isSubmited = false;
        this.loaderService.loading(true);
        if (isEmail) {
            if (this.isPolice) {
                this.forgotPasswordService.forgotPasswordByPoliceEmail(this.forgotPasswordForm.value).subscribe((response: any) => {
                    this.loaderService.loading(false);
                    this.successMessage = response.message;
                    this.forgotPasswordForm.reset();
                }, error => {
                    this.loaderService.loading(false);
                    this.errorMessage = error.error.message;
                });
            } else {
                this.forgotPasswordService.forgotPasswordByEmail(this.forgotPasswordForm.value).subscribe((response: any) => {
                    this.loaderService.loading(false);
                    this.successMessage = response.message;
                    this.forgotPasswordForm.reset();
                }, error => {
                    this.loaderService.loading(false);
                    this.errorMessage = error.error.message;
                });
            }
        } else if (isPhone) {
            const body = {
                phonenumber: this.forgotPasswordForm.value.email
            }
            if (this.isPolice) {
                this.forgotPasswordService.forgotPasswordByPolicePhone(body).subscribe((response: any) => {
                    this.loaderService.loading(false);
                    this.successMessage = response.message;
                    this.router.navigate(['/police/fetch-otp/' + this.forgotPasswordForm.value.email]);
                }, error => {
                    this.loaderService.loading(false);
                    this.errorMessage = error.error.message;
                });
            } else {
                this.forgotPasswordService.forgotPasswordByPhone(body).subscribe((response: any) => {
                    this.loaderService.loading(false);
                    this.successMessage = response.message;
                    this.router.navigate(['/fetch-otp/' + this.forgotPasswordForm.value.email]);
                }, error => {
                    this.loaderService.loading(false);
                    this.errorMessage = error.error.message;
                });
            }
        } else {
            this.loaderService.loading(false);
            this.errorMessage = 'Please enter valid email or phonenumber';
        }
    }
}
