import {RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthComponent} from './auth.component';

const authRoutes: Routes = [
    {
        path: '',
        component: AuthComponent,
        children : [
            {
                path: 'dashboard',
                component: DashboardComponent
            }
        ]
    },
    {
        path: 'houseowner',
        loadChildren: './houseowner/houseowner.module#HouseownerModule'
    },
    {
        path: 'police',
        loadChildren: './police/police.module#PoliceModule'
    },
    {
        path: 'admin',
        loadChildren: './admin/admin.module#AdminModule'
    },
    {
        path: 'tenant',
        loadChildren: './tenant/tenant.module#TenantModule'
    }
]
@NgModule({
    imports: [RouterModule.forChild(authRoutes)],
    exports: [RouterModule]
})
export class AuthRoutingModule {}
