import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {LoginService} from '../../login/login.service';
import {LoaderService} from '../../../loader.service';
import {Router} from '@angular/router';
import {MyErrorStateMatcher} from '../../../MyErrorStateMatcher';
import {Constant} from '../../../Constants';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-google-signup',
    templateUrl: './google.signup.password.html',
    styleUrls: ['./google.signup.password.component.scss'],
    providers: [LoginService]
})
export class GoogleSignupPasswordComponent implements OnInit, AfterViewInit {
    errorMessage;
    successMessage;
    googleSignupPasswordForm: FormGroup;
    floatingLable = 'always';
    errormatcher = new MyErrorStateMatcher();
    googleSignupForm;

    constructor(private _formBuilder: FormBuilder, private loginService: LoginService, private router: Router, private loaderService: LoaderService) {
        this.loaderService.loading(true);
    }

    ngOnInit(): void {
        this.googleSignupPasswordForm = this._formBuilder.group({
            password: [null, Validators.compose([Validators.required])],
            confirm_password: [null, Validators.compose([Validators.required])]
        });
        if (sessionStorage.getItem('google-profile')) {
            this.googleSignupForm = JSON.parse(sessionStorage.getItem('google-profile'));
        } else {
          this.router.navigate(['']);
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loaderService.loading(false);
        }, Constant.ROOT_LOADER);
    }

    googleSignup() {
        debugger
        if (this.googleSignupPasswordForm.invalid || this.googleSignupPasswordForm.status !== 'VALID') {
            return;
        }
        if (this.googleSignupPasswordForm.value.password !== this.googleSignupPasswordForm.value.confirm_password) {
            this.errorMessage = 'Password and confirm password must match.';
            return;
        }
        this.googleSignupForm['password'] = this.googleSignupPasswordForm.value.password;
        this.loginService.houseOwnerGoogleSignup(this.googleSignupForm).subscribe((response: any) => {
            this.successMessage = 'Your password has successfully set.';
            this.googleSignupPasswordForm.reset();
            if (response && response.data.token) {
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('ho_login_data', JSON.stringify(response.data));
                sessionStorage.setItem('token', response.data.token);
                sessionStorage.setItem('ho_login_data', JSON.stringify(response.data));
            }
            localStorage.setItem('houseOwner', JSON.stringify(response.data));
            setTimeout(() => {
                /*this.router.navigate(['/houseowner/dashboard']);*/
                this.router.navigate(['/verify-phone']);
            }, 3000);
        }, error => {
            Swal(
                'Error!',
                error.error.message,
                'error'
            );
            this.errorMessage = error.error.message;
        });
    }
}