import {RouterModule, Routes} from '@angular/router';
import {NoauthComponent} from './noauth.component';
import {NgModule} from '@angular/core';
import {LoginComponent} from './login/login.component';
import {SignupComponent} from './signup/signup.component';
import {VerifyHouseOwnerNumberComponent} from './verify.house.owner.number/verify.house.owner.number.component';
import {CompletionHouseownerComponent} from './completion.houseowner.signup/completion.houseowner.component';
import {ForgotPasswordComponent} from './forgot.password/forgot.password.component';
import {ForgotPasswordResetComponent} from './forgot.password.reset/forgot.password.reset.component';
import {ForgotPasswordResetOtpComponent} from './forgot.password.reset.otp/forgot.password.reset.otp.component';
import {PoliceLoginComponent} from './polish.login/police.login.component';
import {HouseownerVerifyEmailComponent} from './houseowner.verify.email/houseowner.verify.email.component';
import {ResendVerificationEmailComponent} from './resend.verification.email/resend.verification.email.component';
import {AdminLoginComponent} from './admin/admin.login/admin.login.component';
import {GoogleSignupPasswordComponent} from './signup/google.signup.password/google.signup.password.component';
import {HomeComponent} from './home/home.component';
import {PlaceDescriptionComponent} from './place.description/place.description.component';
import {ContactUsComponent} from './contact.us/contact.us.component';
import {AboutUsComponent} from './about.us/about.us.component';
import {TermsAndConditionComponent} from './terms.and.condition/terms.and.condition.component';

const noAuthRoutes: Routes = [
    {
        path: '',
        component: NoauthComponent,
        children : [
            {
                path: '',
                component: HomeComponent,
                data: {title: 'Home'}
            },
            {
                path: 'login',
                component: LoginComponent,
                data: {title: 'Login | Tenant/House Owner'}
            },
            {
                path: 'signup',
                component: SignupComponent,
                data: {title: 'Sign up | House Owner'}
            },
            {
                path: 'google/password',
                component: GoogleSignupPasswordComponent,
                data: {title: 'Google Set Password'}
            },
            {
                path: 'verify-phone',
                component: VerifyHouseOwnerNumberComponent,
                data: {title: 'House Owner - Verify Phone'}
            },
            {
                path: 'completion-profile',
                component: CompletionHouseownerComponent,
                data: {title: 'House Owner Complete Profile'}
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
                data: {title: 'House Owner - Forgot Password'}
            },
            {
                path: 'fetch-otp/:phonenumber',
                component: ForgotPasswordResetOtpComponent,
                data: {title: 'House Owner - Fetch OTP'}
            },
            {
                path: 'reset-password/:code/:id',
                component: ForgotPasswordResetComponent,
                data: {title: 'House Owner - Reset Password'}
            },
            {
                path: 'admin/login',
                component: AdminLoginComponent,
                data: {title: 'Admin Login'}
            },
            {
                path: ':type/login',
                component: PoliceLoginComponent,
                data: {title: 'Police Login'}
            },
            {
                path: ':type/forgot-password',
                component: ForgotPasswordComponent,
                data: {title: 'Police - Forgot Password'}
            },
            {
                path: ':type/fetch-otp/:phonenumber',
                component: ForgotPasswordResetOtpComponent,
                data: {title: 'Police - Fetch OTP'}
            },
            {
                path: ':type/reset-password/:code/:id',
                component: ForgotPasswordResetComponent,
                data: {title: 'Police - Reset Password'}
            },
            {
                path: 'verify/:code/:id',
                component: HouseownerVerifyEmailComponent,
                data: {title: 'House Owner - Verify Email'}
            },
            {
                path: 'resend-email',
                component: ResendVerificationEmailComponent,
                data: {title: 'House Owner - Reset Email'}
            },
            {
                path: 'place-description',
                component: PlaceDescriptionComponent,
                data: {title: 'Place Description'}
            },
            {
                path: 'about-us',
                component: AboutUsComponent,
                data: {title: 'About us'}
            },
            {
                path: 'contact-us',
                component: ContactUsComponent,
                data: {title: 'Contact us'}
            },
            {
                path: 'terms-and-conditions',
                component: TermsAndConditionComponent,
                data: {title: 'Terms and conditions'}
            }
        ]
    }
]
@NgModule({
    imports: [RouterModule.forChild(noAuthRoutes)],
    exports: [RouterModule]
})
export class NoauthRoutingModule {}
