import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthComponent} from './auth.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {AuthRoutingModule} from './auth.routing.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpClientInterceptorService} from './HttpClientInterceptorService';

@NgModule({
    imports: [
        CommonModule,
        AuthRoutingModule,
    ],
    declarations: [
        AuthComponent,
        DashboardComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpClientInterceptorService,
            multi: true
        }
    ]
})
export class AuthModule {}
