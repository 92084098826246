import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor, HttpErrorResponse, HttpResponse
} from '@angular/common/http';
/*import 'rxjs/add/operator/do';*/
import {Router} from '@angular/router';
import {Observable} from 'rxjs/index';
import { map, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class HttpClientInterceptorService implements HttpInterceptor {

    constructor(private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (localStorage.getItem('token')) {
            request = request.clone({
                setHeaders: {
                    authorization: localStorage.getItem('token')
                }
            });
        }
        return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    localStorage.clear();
                    this.router.navigate(['']);
                }
            }
        }));
    }
}
