export const Constants = Object.freeze({
    LOADER_TIME : 1000,
   BASE_API_URL : 'http://162.214.80.64:8085',
   //  BASE_API_URL : 'http://localhost:8085',
    DIALOGWIDTH: '500px',
    DATEFORMAT: 'dd/MM/yyyy'
});
export class Constant {
    static ROOT_LOADER = false;
    static TENANT_ADHAR_FILE = null;
    static TENANT_ID_1 = null;
    static IS_JQUERY_LIGHTER_LOADED = false
    static TENANT_ID_2 = null;
    static MY_DIV_FLAG = false;
    static IS_SUBMIT_CLICKED = false;
}
