import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../Constants';

@Injectable()
export class HomeService {
    constructor(private http: HttpClient) {}

    getInTouch(data) {
        return this.http.post(Constants.BASE_API_URL + '/get-in-touch', data);
    }

    fetchAllProperties(data) {
        return this.http.post(Constants.BASE_API_URL + '/home/properties', data);
    }

    fetchProperties(id) {
        return this.http.get(Constants.BASE_API_URL + '/home/properties/view/' + id);
    }

    fetchSimilarProperties(id) {
        return this.http.get(Constants.BASE_API_URL + '/home/similar-properties/' + id);
    }

    requestCallBack(data) {
        return this.http.post(Constants.BASE_API_URL + '/home/request-callback/', data);
    }
}