import {AfterViewInit, Component, OnInit} from '@angular/core';
import {LoaderService} from '../../loader.service';
import {Constants} from '../../Constants';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {MyErrorStateMatcher} from '../../MyErrorStateMatcher';
import Swal from 'sweetalert2';
import {HomeService} from './home.service';
import {RandomAdvertisementService} from '../../auth/RandomAdvertisementService';
declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.html',
    styleUrls: ['./home.scss'],
    providers: [HomeService, RandomAdvertisementService]
})
export class HomeComponent implements OnInit, AfterViewInit {
    homeForm: FormGroup;
    floatingLable = 'auto';
    errormatcher = new MyErrorStateMatcher();
    advt1 = '';
    advt2 = '';
    totalItems;
    search;
    /*property = [
        {
            name: 'Name of property',
            image: './assets/imgs/home-p-i.jpg',
            price: '$ 1000 / mo.'
        },
        {
            name: 'Name of property',
            image: './assets/imgs/home-p-i.jpg',
            price: '$ 1000 / mo.'
        },
        {
            name: 'Name of property',
            image: './assets/imgs/home-p-i.jpg',
            price: '$ 1000 / mo.'
        },
        {
            name: 'Name of property',
            image: './assets/imgs/home-p-i.jpg',
            price: '$ 1000 / mo.'
        }
    ];*/
    bunches = [];

    constructor(private _formBuilder: FormBuilder,
                private homeService: HomeService,
                private randomAdvertisementService: RandomAdvertisementService,
                private loaderService: LoaderService) {
        this.loaderService.loading(true);
    }

    FetchAdvertisement(data) {
        return this.randomAdvertisementService.RandomAdvertisement(data);
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.loaderService.loading(false);
        }, Constants.LOADER_TIME);
    }

    InquirySubmit() {
        if (this.homeForm.invalid || this.homeForm.status !== 'VALID') {
            return;
        }
        Swal('Success!', 'Thank you for get in touch. Our customer executive will shortly contact you!', 'success');
        this.homeService.getInTouch(this.homeForm.value).subscribe((response: any) => {
        });
        this.homeForm.reset();
    }

    ngOnInit(): void {
        this.homeForm = this._formBuilder.group({
            name: [null, Validators.compose([Validators.required])],
            email: [null, Validators.compose([Validators.required, Validators.email])],
            phone: [null, Validators.compose([Validators.required])],
            interestedProduct: [null, Validators.compose([Validators.required])],
            comments: [null, Validators.compose([Validators.required])],
        });
        this.homeForm.patchValue({interestedProduct: ''});
        /*this.router.*/
        this.FetchAdvertisement('?footer=true').subscribe((response: any) => {
            this.advt1 = 'url("' + response.data.image + '")';
        }, error => {
        });
        this.FetchAdvertisement('?header=true').subscribe((response: any) => {
            this.advt2 = 'url("' + response.data.image + '")';
        }, error => {
        });
        this.PerformThis('');
    }

    PerformThis(search) {
        this.homeService.fetchAllProperties({search: search}).subscribe((response: any) => {
            const allProperties = response.data;
            setTimeout(() => {
                $('.carousel').carousel({
                    interval: 2000
                });
                $('.carousel').carousel('next');
            }, 1000);
            this.totalItems = response.data.length;
            let bunchProperties = [];
            this.bunches = []
            for (let i = 0; i < allProperties.length; i++) {
                if (i % 4 === 0 && i !== 0) {
                    this.bunches.push(bunchProperties);
                    bunchProperties = [];
                }
                bunchProperties.push(allProperties[i]);
            }
            if (bunchProperties.length > 0) {
                this.bunches.push(bunchProperties);
            }
        });
    }

    SearchIt() {
        debugger
        this.PerformThis(this.search);
    }

}