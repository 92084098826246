import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Constants} from '../../Constants';

@Injectable()
export class HouseownerVerifyEmailService {

    constructor(private http: HttpClient) {}

    verifyMyEmail(data) {
        return this.http.post(Constants.BASE_API_URL + '/verify-email/house-owner', data);
    }
}