import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NoauthRoutingModule} from './noauth.routing.module';
import {NoauthComponent} from './noauth.component';
import {LoginComponent} from './login/login.component';
import {
    MatButtonModule, MatCheckboxModule, MatDialogModule, MatFormFieldModule, MatIconModule, MatInputModule,
    MatSelectModule,
    MatTabsModule
} from '@angular/material';
import {SignupComponent} from './signup/signup.component';
import {OnlyNumber} from '../only.number.directive';
import {VerifyHouseOwnerNumberComponent} from './verify.house.owner.number/verify.house.owner.number.component';
import {CompletionHouseownerComponent} from './completion.houseowner.signup/completion.houseowner.component';
import {ForgotPasswordComponent} from './forgot.password/forgot.password.component';
import {ForgotPasswordResetOtpComponent} from './forgot.password.reset.otp/forgot.password.reset.otp.component';
import {ForgotPasswordResetComponent} from './forgot.password.reset/forgot.password.reset.component';
import {PoliceLoginComponent} from './polish.login/police.login.component';
import {LoaderService} from '../loader.service';
import {HouseownerVerifyEmailComponent} from './houseowner.verify.email/houseowner.verify.email.component';
import {ResendVerificationEmailComponent} from './resend.verification.email/resend.verification.email.component';
import {AdminLoginComponent} from './admin/admin.login/admin.login.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {GoogleSignupPasswordComponent} from './signup/google.signup.password/google.signup.password.component';
import {HomeComponent} from './home/home.component';
import {PlaceDescriptionComponent} from './place.description/place.description.component';
import {ContactUsComponent} from './contact.us/contact.us.component';
import {AboutUsComponent} from './about.us/about.us.component';
import {TermsAndConditionComponent} from './terms.and.condition/terms.and.condition.component';
import {RequestCallbackComponent} from './place.description/request.callback/request.callback.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        NoauthRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatIconModule,
        MatDialogModule,
        MatCheckboxModule,
        MatButtonModule
    ],
    declarations: [
        NoauthComponent,
        LoginComponent,
        VerifyHouseOwnerNumberComponent,
        SignupComponent,
        CompletionHouseownerComponent,
        ForgotPasswordComponent,
        ForgotPasswordResetOtpComponent,
        ForgotPasswordResetComponent,
        PoliceLoginComponent,
        HouseownerVerifyEmailComponent,
        ResendVerificationEmailComponent,
        AdminLoginComponent,
        GoogleSignupPasswordComponent,
        HomeComponent,
        ContactUsComponent,
        AboutUsComponent,
        TermsAndConditionComponent,
        PlaceDescriptionComponent,
        RequestCallbackComponent,
        OnlyNumber
    ],
    providers: [
        LoaderService
    ],
    entryComponents: [RequestCallbackComponent]
})
export class NoauthModule {}
